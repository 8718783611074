<template>
  <div class="prepaidPageSection">
    <div class="mobileNav">Profile</div>
    <b-container class="profile">
      <!-- style - 1  -->
      <b-row class="p-3">
        <b-col cols="12" md="6" class="my-3 px-4">
          <PersonalProfile />
          <ChangePassword />
        </b-col>
        <b-col cols="12" md="6" class="my-3 px-4">
          <div v-show="!isEndDayToday && !isCibc">
            <AutoPayment />
          </div>
        </b-col>
      </b-row>

      <!-- style - 2  -->
      <!-- <b-row class=" p-3 d-flex flex-row justify-content-center">
          <b-col cols="12" md="6" class="px-4">
            <PersonalProfile />
          </b-col>
        </b-row>
        <b-row class="p-3  d-flex flex-row justify-content-center">
          <b-col cols="12" md="6" class="px-4">
            <ChangePassword />
          </b-col>
        </b-row> -->
    </b-container>
  </div>
</template>

<script>
import PersonalProfile from "./ProfileComponents/PersonalProfile";
import ChangePassword from "./ProfileComponents/ChangePassword";
import AutoPayment from "./ProfileComponents/AutoPayment";

import moment from "moment";
export default {
  name: "Plan",
  data() {
    return {
      // terminationStatus: "",
      // terminationDate: "",

      edate: "",

      isCibc: false, // for CIBC
    };
  },
  components: {
    PersonalProfile,
    ChangePassword,
    AutoPayment,
  },
  methods: {
    getPrepaidUserInfo() {
      this.$axios
        .get("prepaid/prepaiduserinfo")
        .then((response) => {
          let data = JSON.parse(response.data);
          this.edate = data.edate;
          this.checkIfTodayIsTheEndDay();
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getAccountInfo() {
      this.$axios
        .get("Account/AccountInfo")
        .then((response) => {
          let data = JSON.parse(response.data);
          if (data.CurrentPlan_pfId == 1174) {
            this.isCibc = true;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    checkIfTodayIsTheEndDay() {
      let today = new moment.utc();
      let todayY = today.year();
      let todayM = today.month() + 1; // this must have someproblem. but let's keep the origin logic for now.
      todayM < 10 && (todayM = "0" + todayM);
      let todayD = today.date();
      let currentDay = todayY + "-" + todayM + "-" + todayD;

      // console.log("currentDay", currentDay);
      // console.log("this.edate", this.edate);

      // currentDay = "2022-07-16";

      this.edate <= currentDay && (this.isEndDayToday = true);
      // console.log(this.isEndDayToday);
    },
  },
  mounted() {
    this.$store.commit("setPrepaidCurrentNav", "Profile");

    this.getPrepaidUserInfo();
    this.getAccountInfo();
  },
};
</script>

<style lang="scss">
.profile {
  h3 {
    font-size: 22px;
  }

  .closeIcon {
    font-size: 24px;
    top: 14px;
    right: 14px;
  }

  .title {
    font-weight: 500;
  }
}
</style>
