<template>
  <div>
    <h3 class="mb-4">Auto Extension Set Up</h3>
    <div class="bg-white p-2 mycard">
      <div v-if="!changeCreditCard">
        <div v-if="isAutoPayment">
          <div
            class="bg-blue text-white shadow my-5 p-3 creditCard position-relative"
          >
            <p style="font-size: 18px">
              <span>Name</span>
              <br />
              <span>
                {{ cc.name }}
              </span>
            </p>
            <b-row class="cardNumber">
              <b-col cols="2">
                <p>....</p>
              </b-col>
              <b-col cols="2">
                <p>....</p>
              </b-col>
              <b-col cols="2">
                <p>....</p>
              </b-col>
              <b-col cols="2">
                <p>{{ cc.last4Digits }}</p>
              </b-col>
            </b-row>
            <p>
              <span>Expiry Date</span> <br />
              <span> {{ cc.month }} / {{ cc.year[2] + cc.year[3] }}</span>
            </p>
            <img
              class="cardImage"
              :src="require('../../../assets/creditCards/' + cc.type + '.png')"
            />
          </div>
        </div>
        <div v-else>
          <div
            class="m-5 p-4 mycard text-white bg-blue shadow"
            style="border-radius: 10px"
          >
            <p class="m-0 text-center" style="font-size: 18px">
              Please add a credit card for auto extension
            </p>
          </div>
        </div>

        <div class="my-4">
          <b-row class="text-center my-3">
            <b-col class="mb-2">
              <button
                class="mybtn-blue"
                v-show="!isEndDayToday"
                @click="changeCreditCard = true"
              >
                <div v-show="isAutoPayment">Change Payment Method</div>
                <div v-show="!isAutoPayment">Add your card</div>
              </button>
            </b-col>
          </b-row>

          <b-row class="text-center my-3">
            <b-col class="mb-2">
              <button
                :disabled="!isAutoPayment"
                @click="openTheConfirmationMsgBox"
                :class="isAutoPayment ? 'mybtn-blue' : 'mybtn-block'"
              >
                Cancel Auto extension
              </button>
            </b-col>
          </b-row>
        </div>
      </div>

      <!-- modify credit card info payment  -->
      <div
        v-else
        class="bg-white shadow-lg p-3 mt-3 mb-4 mycard position-relative"
      >
        <i
          class="fas fa-window-close position-absolute closeIcon"
          @click="changeCreditCard = false"
        ></i>

        <h3 class="title text-info text-center my-4" v-show="isAutoPayment">
          Modify
        </h3>
        <h3 class="title text-info text-center my-4" v-show="!isAutoPayment">
          Add
        </h3>

        <b-form @submit="handleChangeCreditCard">
          <b-row>
            <b-col cols="12" md="6" class="mb-3">
              <label>Credit Card Number</label>
              <b-form-input
                v-model="card.number"
                class="my-input"
                :state="numberState"
                required
              ></b-form-input>
              <b-form-invalid-feedback>
                Incorrect Credit Card Number
              </b-form-invalid-feedback>
            </b-col>

            <b-col cols="12" md="6" class="mb-3">
              <label>Card Holder Name</label>
              <b-form-input
                v-model="card.name"
                class="my-input"
                :state="nameState"
                required
              ></b-form-input>

              <b-form-invalid-feedback>
                Please enter your full name
              </b-form-invalid-feedback>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="12" md="6" class="mb-3">
              <label>Expiration Date (MM/YY)</label>
              <b-form-input
                v-model="card.date"
                class="my-input"
                :state="dateState"
                required
              ></b-form-input>
              <b-form-invalid-feedback>
                Correct format: MM/YY (MM from 01 - 12)
              </b-form-invalid-feedback>
            </b-col>
            <b-col cols="12" md="6" class="mb-3">
              <label>CVV</label>
              <b-form-input
                v-model="card.cvv"
                class="my-input"
                :state="cvvState"
                required
              ></b-form-input>
              <b-form-invalid-feedback>
                Please enter 3 digits on the backend of your card
              </b-form-invalid-feedback>
            </b-col>
          </b-row>
          <b-row class="d-flex justify-content-center my-4 px-5">
            <button type="submit" class="mybtn-blue">Save</button>
          </b-row>
        </b-form>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment';
export default {
  data() {
    return {
      changeCreditCard: false,
      cc: {
        name: "",
        last4Digits: "",
        type: "",
        month: "",
        year: "",
      },
      card: {
        name: "",
        number: "",
        date: "",
        cvv: "",
      },
      isAutoPayment: false,
      isEndDayToday: false,
    };
  },
  computed: {
    numberState() {
      //Luhn algorithm
      let cardNo = this.card.number;
      if (cardNo === "") {
        return false;
      }
      let s = 0;
      let doubleDigit = false;
      for (let i = cardNo.length - 1; i >= 0; i--) {
        let digit = +cardNo[i];
        if (doubleDigit) {
          digit *= 2;
          if (digit > 9) digit -= 9;
        }
        s += digit;
        doubleDigit = !doubleDigit;
      }
      return s % 10 == 0;
    },
    nameState() {
      return this.card.name !== "";
    },
    dateState() {
      let data = this.card.date;
      if (data.length !== 5) {
        return false;
      } else if (!data.includes("/")) {
        return false;
      } else {
        let month = data.split("/")[0];
        let year = data.split("/")[1];
        if (
          month.length === 2 &&
          year.length === 2 &&
          this.isNumber(month) &&
          this.isNumber(year) &&
          parseInt(month) >= 1 &&
          parseInt(month) <= 12
        ) {
          return true;
        } else {
          return false;
        }
      }
    },
    cvvState() {
      return this.isNumber(this.card.cvv) && this.card.cvv.length === 3;
    },
  },
  methods: {
    isNumber(n) {
      return /^-?[\d.]+(?:e-?\d+)?$/.test(n);
    },
    resetForm() {
      this.card.name = "";
      this.card.number = "";
      this.card.date = "";
      this.card.cvv = "";
    },
    openTheConfirmationMsgBox() {
      this.$confirm(
        "Are you sure you want to cancel your credit card auto payment？",
        "Warning",
        {
          confirmButtonText: "Yes",
          cancelButtonText: "No",
          type: "warning",
        }
      ).then(() => {
        this.setAutoPayment(false);
      });
    },
    setAutoPayment(value) {
      this.$axios
        .post("/Account/ChangeAutoPayment?autopayment=" + value)
        .then(() => {
          this.resetForm();
          this.getProfile();
          if (value) {
            this.$notify({
              title: "Success",
              message: "Successfully add your credit card to auto payment !",
              type: "success",
            });
          } else {
            this.$notify({
              title: "Success",
              message: "Successfully cancel your credit card auto payment !",
              type: "success",
            });
          }
        })
        .catch((error) => {
          console.log(error);
          this.$notify({
            title: "Error",
            message: "Netowrk Error !",
            type: "error",
          });
        });
    },

    handleChangeCreditCard(event) {
      event.preventDefault();
      this.changeCreditCard = false;
      let data = {
        CreditCardNumber: this.card.number,
        CreditCardName: this.card.name,
        CCV: this.card.cvv,
        ExpirationMonth: this.card.date.split("/")[0],
        ExpirationYear: "20" + this.card.date.split("/")[1],
      };
      this.$axios
        .post("/Prepaid/UpdateAutoExtensionPaymentInfo", data)
        .then((response) => {
          let data = response.data;
          data = data.substring(1, data.length - 1);
          if (data === "Successful") {
            this.setAutoPayment(true);
          }
        })
        .catch((error) => {
          console.log(error);
          this.$notify({
            title: "Error",
            message:
              "Sorry, failed to set up auto payment with your credit card !",
            type: "error",
          });
        });
    },
    getProfile() {
      this.$axios
        .get("/Account/GetProfile")
        .then((response) => {
          let data = JSON.parse(response.data);
          this.isAutoPayment = data.AutoPayment;
          if (this.isAutoPayment) {
            this.cc.last4Digits = data.CreditCardLast4Digits;
            this.cc.name = data.CreditCardHolderName;
            this.cc.month = data.CreditCardExpirationMonth;
            this.cc.year = data.CreditCardExpirationYear;
            // Visa Master AmericanExpress Discover DinersClub JCB
            this.cc.type = data.CreditCardType;
          }
        })
        .catch((error) => {
          console.log(error);
        });
      // check if today is the end day -> block auto payment
      this.$axios
        .get("prepaid/prepaiduserinfo")
        .then((response) => {
          let data = JSON.parse(response.data);
          let edate = data.edate;
          let today = new moment.utc();
          let todayY = today.year();
          let todayM = today.month() + 1;
          todayM < 10 && (todayM = "0" + todayM);
          let todayD = today.date();
          let currentDay = todayY + "-" + todayM + "-" + todayD;
          edate <= currentDay && (this.isEndDayToday = true); // updated from "===" according to VUE-326
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
  mounted() {
    this.getProfile();
  },
};
</script>

<style lang="scss" scoped>
button {
  width: 300px;
}

.creditCard {
  border-radius: 10px;
  width: 80%;
  margin: auto;
}

.creditCard p {
  font-weight: 300;
  margin: 10px;
}

.cardNumber p {
  font-size: 20px;
}

.cardImage {
  position: absolute;
  right: 30px;
  bottom: 5px;
  width: 80px;
}

@media (max-width: 768px) {
  .creditCard {
    width: 90%;
  }

  .cardImage {
    width: 60px;
    bottom: 20px;
    right: 20px;
  }

  button {
    width: 240px;
  }
}
</style>
